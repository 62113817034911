import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { AnimationEvent } from "react";
import { useTranslation } from "react-i18next";
import "../layout.css";

const colors: string[] = ["#222222", "#333333", "#444444"];

export default () => {
  let count = 0;
  const { i18n } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        first: markdownRemark(
          frontmatter: { index: { eq: 1 }, langKey: { eq: "en-US" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        second: markdownRemark(
          frontmatter: { index: { eq: 2 }, langKey: { eq: "en-US" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        third: markdownRemark(
          frontmatter: { index: { eq: 3 }, langKey: { eq: "en-US" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        firstFr: markdownRemark(
          frontmatter: { index: { eq: 1 }, langKey: { eq: "fr" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        secondFr: markdownRemark(
          frontmatter: { index: { eq: 2 }, langKey: { eq: "fr" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        thirdFr: markdownRemark(
          frontmatter: { index: { eq: 3 }, langKey: { eq: "fr" } }
        ) {
          id
          frontmatter {
            title
            langKey
            index
            buttonTitle
            buttonUrl
          }
          excerpt(pruneLength: 1200, format: HTML)
        }
        suryaSoftLogo: file(relativePath: { eq: "surya-soft-logo.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  function incrementCount(event?: AnimationEvent<HTMLDivElement>): void {
    count++;
  }
  const lang = i18n.language;
  const dataFirst = lang === "en-US" ? data.first : data.firstFr;
  const dataSecond = lang === "en-US" ? data.second : data.secondFr;
  const dataThird = lang === "en-US" ? data.third : data.thirdFr;

  return (
    <div className="society-container">
      <div className="card-div1">
        <div
          className="card"
          style={{
            backgroundColor: colors[count],
            marginTop: `-27rem`
          }}
        >
          {incrementCount()}
          <div dangerouslySetInnerHTML={{ __html: dataFirst.excerpt }} />
          <button
            className="button"
            onClick={() => {
              navigate(dataFirst.frontmatter.buttonUrl);
            }}
            style={{
              float: `right`,
              height: `50px`,
              margin: `30px`,
              width: `auto`
            }}
          >
            {dataFirst.frontmatter.buttonTitle + " >> "}
          </button>
        </div>
        <div className="card-right" style={{ backgroundColor: colors[count] }}>
          {incrementCount()}
          <div dangerouslySetInnerHTML={{ __html: dataSecond.excerpt }} />
          <button
            className="button"
            onClick={() => {
              navigate(dataSecond.frontmatter.buttonUrl);
            }}
            style={{
              float: `right`,
              height: `50px`,
              margin: `30px`,
              width: `auto`
            }}
          >
            {dataSecond.frontmatter.buttonTitle + " >> "}
          </button>
        </div>
      </div>
      <div className="card-div2">
        <div className="card-lower" style={{ backgroundColor: colors[count] }}>
          {incrementCount()}
          <div dangerouslySetInnerHTML={{ __html: dataThird.excerpt }} />
          <button
            className="button"
            onClick={() => {
              navigate(dataThird.frontmatter.buttonUrl);
            }}
            style={{
              float: `right`,
              height: `50px`,
              margin: `30px`,
              width: `auto`
            }}
          >
            {dataThird.frontmatter.buttonTitle + " >> "}
          </button>
        </div>
        <div className="society-logos-div">
          <a href="https://www.surya-soft.com/" className="society-logos-link">
            <Img
              className="society-div2-logo"
              style={{ width: `124px` }}
              fluid={data.suryaSoftLogo.childImageSharp.fluid}
            />
            www.surya-soft.com
          </a>
        </div>
      </div>
    </div>
  );
};

import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import React from "react";
import { Parallax } from "react-scroll-parallax";
import tilt_border from "../../../assets/tilt_border.png";
import Excerpt from "../excerpt";

export default ({
  className
}: {
  className?: string;
  excerpt?: string;
  highlight?: string;
  excerptEnd?: string;
  scrollId?: string;
}) => {
  return (
    <div>
      <Parallax
        styleInner={{
          height: `inherit`
        }}
        className={className}
      />
      <div
        style={{
          marginTop: `-8%`
        }}
      >
        <img
          className="container-header"
          alt="Bottom Border"
          style={{ animation: `neutral 8s forwards` }}
          src={tilt_border}
        />
      </div>
    </div>
  );
};

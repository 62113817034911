import React from "react";
import Container from "../components/container";
import ContainerWithHeaderOnly from "../components/containerWithHeaderOnly";
import ImageContainerWithHeaderOnly from "../components/imageContainerWithHeaderOnly";
import Layout from "../components/layout";
import "../components/layout.css";
import SocietyContainer from "../components/society/societyContainer";
import SocietyImageContainerWithHeaderOnly from "../components/society/societyImageContainerWithHeaderOnly";
import { withTranslation } from "react-i18next";

class Society extends React.Component {
  public render() {
    return (
      <Layout>
        <SocietyImageContainerWithHeaderOnly className="background-society" />
        <div>
          <Container style={{ overflow: `visible` }}>
            <SocietyContainer />
          </Container>
        </div>
      </Layout>
    );
  }
}

export default withTranslation()(Society);
